import type { StandardTextFieldProps } from '@mui/material/TextField';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from 'features/ui/elements';

export interface TextareaProps extends StandardTextFieldProps {
  value?: string;
}

export const Textarea: FC<TextareaProps> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'textarea' });

  const maxLength = useMemo(
    () => props.inputProps?.maxLength,
    [props.inputProps?.maxLength]
  );

  const isMaxLengthExceeded = useMemo(
    () => !!props.value && maxLength && props.value.length > maxLength,
    [props.value, maxLength]
  );

  const helperText = useMemo(() => {
    if (!maxLength) {
      return;
    }

    const value = props.value;

    if (!value) {
      return t('max', { count: maxLength });
    }

    if (isMaxLengthExceeded) {
      return t('max-exceeded');
    }

    return `${value.length}/${maxLength}`;
  }, [t, maxLength, props.value, isMaxLengthExceeded]);

  const inputProps = useMemo(() => {
    if (!maxLength) {
      return props.inputProps;
    }

    return {
      ...props.inputProps,
      maxLength: maxLength + 1,
    };
  }, [props.inputProps, maxLength]);

  return (
    <TextField
      {...props}
      type='text'
      multiline
      rows={props.rows || 5}
      fullWidth
      error={props.error || isMaxLengthExceeded}
      inputProps={inputProps}
      helperText={helperText}
    />
  );
};
