import { useMemo, type FC } from 'react';

import { Hotkey } from 'config/enums';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallDetailsButtonDisconnect } from 'features/call/call-details/components';
import { useAppSelector } from 'features/app/hooks';
import { isTeamingInProgressSelector } from 'features/teaming/teaming-base/store';

interface CallDetailsButtonDeafDisconnectProps {
  loading?: boolean;
}

export const CallDetailsButtonDeafDisconnect: FC<
  CallDetailsButtonDeafDisconnectProps
> = ({ loading }) => {
  const { handleDeafDisconnect } = useCallDeafParticipantHandlers();

  const isTeamingInProgress = useAppSelector(isTeamingInProgressSelector);

  const buttonStyles = useMemo(
    () => ({
      display: isTeamingInProgress ? 'none' : 'block',
    }),
    [isTeamingInProgress]
  );

  return (
    <CallDetailsButtonDisconnect
      loading={loading}
      disabled={isTeamingInProgress}
      onClick={handleDeafDisconnect}
      hotkey={Hotkey.CallerConnectionStateChange}
      sx={buttonStyles}
    />
  );
};
