export enum CallNotificationType {
  AUTO_LOGOUT = 'AutoLogout',
  PUSHED_CALL_TIMEOUT = 'PushedCallTimeout',
  CALL_DISCONNECTED = 'CallDisconnect',
  NEW_CALL = 'NewCall',
  CALL_STARVATION_TIMEOUT = 'CallStarvationTimeout',
  INITIATE_DIAL = 'InitiateDial',

  TEAMING_REQUEST = 'TeamingRequest',
  TEAMING_ACCEPTED = 'TeamingAccepted',
  TEAMING_CANCELED = 'TeamingCanceled',
  TEAMING_BEGIN = 'TeamingBegin',
  TEAMING_END = 'TeamingEnd',
  TEAMING_HANDOFF = 'TeamingHandoff',
  SYNC_CALL_TIMER = 'SyncCallTimer',
  TEAMING_USER_CONNECTED_DEPRECATED = 'TeamingUserConnectedDeprecated',
  TEAMING_USER_CONNECTION_SUCCESS = 'TeamingUserConnectionSuccess',
  TEAMING_USER_CONNECTION_FAILED = 'TeamingUserConnectionFailed',

  HANDOFF_CALL = 'HandoffCall',
  HANDOFF_ACCEPTED = 'HandoffAccepted',
  HANDOFF_CANCELED = 'HandoffCanceled',
  HANDOFF_CALL_SENT = 'HandoffCallSent',

  ADMIN_AUTO_LOGOUT = 'AdminAutoLogout',
  ADMIN_CANCELED_CALL = 'AdminCanceledCall',
  ADMIN_TEAMING_CANCELED = 'AdminTeamingCanceled',
  ADMIN_TEAMED_USER_LOGOUT = 'AdminTeamedUserLogout',
  REMOTE_TEAMED_USER_LOGOUT = 'RemoteTeamedUserLogout',

  MONITOR_START = 'MonitorStart',
  MONITOR_END = 'MonitorEnd',

  VOICE_SESSION_CREATING_STARTED = 'VoiceSessionCreatingStarted',
  VOICE_SESSION_CREATING_FAILED = 'VoiceSessionCreatingFailed',
  VOICE_SESSION_CREATING_COMPLETED = 'VoiceSessionCreatingCompleted',

  HEARING_DECLINED_CALL = 'HearingDeclinedCall',
  SPAWN_CALL = 'SpawnCall',
  INFO_FOR_VI = 'InfoForVI',
}
