import { Menu, MenuItem } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { DropdownIcon } from './components';

import { MarkIcon } from 'assets/svg';
import { usePopover } from 'features/menu/hooks';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import type { SelectItem } from 'features/ui/elements';
import { Button } from 'features/ui/elements';
import type { DropDownColor } from 'features/ui/interfaces';

interface DropdownButtonProps {
  title: string;
  value: SelectItem['value'];
  options: SelectItem[];
  width: number;
  onClick: () => void;
  onChange: (value: SelectItem['value']) => void;
  loading: boolean;
  color: DropDownColor;
  className?: string;
}

const dropdownIconWidth = theme.spacing(23);

const Container = styled('div')`
  display: flex;
`;

const DropdownButtonStyled = styled(Button)`
  width: 100%;
  border-radius: 8px 0 0 8px;
`;

const OptionTextMultiline = styled('span')`
  white-space: initial;
`;

const MarkIconSelected = styled(MarkIcon)`
  min-width: 16px;
  margin-left: 10px;
`;

export const DropdownButton: FC<DropdownButtonProps> = ({
  title,
  value,
  options,
  width,
  onClick,
  onChange,
  className,
  loading,
  color,
}) => {
  const { isOpenPopover, popoverId, anchorEl, showPopover, closePopover } =
    usePopover('dropdown-menu');

  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const valueIndex = options.findIndex((option) => option.value === value);
    setSelectedIndex(valueIndex);
  }, [options, value]);

  const handleOption = (index: number) => {
    setSelectedIndex(index);
    onChange(options[index].value);
    closePopover();
  };

  return (
    <Container className={className}>
      <DropdownButtonStyled
        fullWidth
        size='small'
        color={color}
        onClick={() => onClick()}
        loading={loading}
      >
        {title}
      </DropdownButtonStyled>
      <DropdownIcon
        isOpen={isOpenPopover}
        onClick={showPopover}
        iconColor={color}
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpenPopover}
        onClose={closePopover}
        aria-describedby={popoverId}
        classes={{ paper: 'select' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -width + parseInt(dropdownIconWidth),
        }}
      >
        {options.length !== 0 &&
          options.map((option, index) => (
            <MenuItem
              key={index}
              sx={{ width: width }}
              onClick={() => handleOption(index)}
            >
              <OptionTextMultiline>{option.text}</OptionTextMultiline>
              {index === selectedIndex && <MarkIconSelected />}
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
};
