import styled from '@emotion/styled';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { setPrimaryHearingPhoneNumber } from 'features/call/call-hearing/store';
import { EmergencyConnectionPoint } from 'features/emergency/enums';
import {
  emergencyConnectionPointSelector,
  emergencyPhoneNumberSelector,
  setEmergencyConnectionPoint,
} from 'features/emergency/store';
import theme from 'features/theme/theme';
import { DropdownButton } from 'features/ui/components';
import { Color } from 'features/ui/interfaces';
import { shouldCallControlsBeVisibleSelector } from 'features/spawn-call/store';

export const EmergencyNewCall: FC = () => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'emergencyEndpointOption',
  });
  const dispatch = useAppDispatch();
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  );
  const emergencyPhoneNumber = useAppSelector(emergencyPhoneNumberSelector);

  const handleChange = useCallback(
    (value: EmergencyConnectionPoint) => {
      dispatch(setEmergencyConnectionPoint(value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const shouldCallControlsBeVisible = useAppSelector(
    shouldCallControlsBeVisibleSelector
  );

  const emergencyEndpoints = {
    title: ct('newCall.title'),
    options: [
      {
        text: t('psap'),
        value: EmergencyConnectionPoint.PSAP,
      },
      {
        text: t('ecrc'),
        value: EmergencyConnectionPoint.ECRC,
      },
      {
        text: t('other'),
        value: EmergencyConnectionPoint.OTHER,
      },
    ],
    width: 242,
    loading: false,
  };

  useEffect(() => {
    dispatch(setPrimaryHearingPhoneNumber(emergencyPhoneNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyConnectionPoint]);

  if (!shouldCallControlsBeVisible) {
    return;
  }

  return (
    <StyledDropdownButton
      {...emergencyEndpoints}
      color={Color.SECONDARY}
      value={emergencyConnectionPoint}
      onClick={() => {}}
      onChange={handleChange}
    />
  );
};

const StyledDropdownButton = styled(DropdownButton)`
  margin-top: ${theme.spacing(6)};
`;
