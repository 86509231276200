import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { callIdSelector } from 'features/call/call-base/store/selectors';
import { deafPhoneNumberByModeSelector } from 'features/call/call-deaf/store';
import { deafPhoneNumberIsHiddenSelector } from 'features/deaf-phone-number-hidden/store';
import { handleError } from 'features/notification/store';
import { isPrimaryVcoSenderSelector } from 'features/vco/store';
import {
  createHearingParticipant,
  createMonitoringParticipant,
} from 'features/voice-meeting/helpers';
import {
  useVoiceMeetingAudio,
  useVoiceMeetingContext,
} from 'features/voice-meeting/hooks';
import { VoiceMeetingEventBus } from 'features/voice-meeting/services';
import type {
  Participant,
  VoiceSessionAddParticipantRequest,
} from 'features/voice-session/interfaces';
import { VoiceSessionDaoService } from 'features/voice-session/services';
import { setHearingAsDisconnected } from 'features/call/call-hearing/store';
import { setCallDetailsCalleeStatus } from 'features/call/call-details/store';
import { CallDetailsStatus } from 'features/call/call-details/enums';
import { sendAnalyticsError } from 'features/analytics/helpers';
import { selectIsInCallerSession } from 'features/caller-session/store';
import { LogLevel } from 'features/analytics/enums';

export const useVoiceMeetingSession = () => {
  const dispatch = useAppDispatch();
  const { service } = useVoiceMeetingContext();
  const { getCurrentMeetingAudioStream } = useVoiceMeetingAudio();
  const callId = useAppSelector(callIdSelector);
  const deafPhoneNumberIsHidden = useAppSelector(
    deafPhoneNumberIsHiddenSelector
  );
  const deafPhoneNumberByMode = useAppSelector(deafPhoneNumberByModeSelector);
  const isPrimaryVcoSender = useAppSelector(isPrimaryVcoSenderSelector);
  const isInCallerSession = useAppSelector(selectIsInCallerSession);

  // Cleans up any  chime session if for any reason it survives outside a caller session.
  useEffect(() => {
    // TODO: Consider writing a test for this effect
    if (service.meetingSession && !isInCallerSession) {
      service.completeMeetingSession();
      dispatch(
        sendAnalyticsError({
          Method: 'useVoiceMeetingSession',
          Level: LogLevel.INFO,
          Message:
            'Session is not in caller session and meetingSession not null',
        })
      );
    }
  }, [dispatch, isInCallerSession, service]);

  const completeMeeting = () => {
    if (!service.meetingSession) {
      console.warn('DEBUG: Meeting session not found');
      return;
    }

    service.completeMeetingSession();
  };

  const syncSession = async () => {
    const sessionId = service.getSessionId();

    if (!sessionId) {
      console.warn('DEBUG: Voice session not found');
      return;
    }

    const newSessionState =
      await VoiceSessionDaoService.getVoiceSessionById(sessionId);

    service.setVoiceSession(newSessionState);
  };

  const startMeeting = async () => {
    if (!service.meetingSession) {
      console.warn('DEBUG: Meeting session not found');
      return;
    }

    service.meetingSession.audioVideo.start();
    await getCurrentMeetingAudioStream();
  };

  const addParticipant = async (phoneNumber: string) => {
    const payload = getVoiceSessionPayload(phoneNumber);
    const newSessionState =
      await VoiceSessionDaoService.addParticipant(payload);
    service.setVoiceSession(newSessionState);

    return service.getHearingByPhoneNumber(phoneNumber);
  };

  const addMonitorParticipant = async (
    adminContact: string,
    voiceSessionId: string
  ) => {
    const participant = createMonitoringParticipant(adminContact);
    await VoiceSessionDaoService.addParticipant({
      participant,
      sessionId: voiceSessionId,
      callId,
    });
  };

  const disconnectParticipant = async (participantId: string) => {
    const sessionId = service.getSessionId();
    const updateStatus = () => {
      dispatch(setHearingAsDisconnected(participantId));
      dispatch(
        setCallDetailsCalleeStatus({
          isDeafParticipant: false,
          status: CallDetailsStatus.CALLEE_RECONNECT,
        })
      );
    };

    try {
      const voiceSession =
        await VoiceSessionDaoService.getVoiceSessionById(sessionId);
      const participantExists = voiceSession.participants.some(
        (p) => p.id === participantId
      );

      if (!participantExists) {
        updateStatus();
        return;
      }

      await VoiceSessionDaoService.deleteParticipant({
        sessionId,
        participantId,
      });
      updateStatus();
    } catch (error) {
      updateStatus();
      dispatch(
        handleError({
          error,
          methodName: 'disconnectParticipant',
        })
      );
    }
  };
  const getSessionAndReturnParticipant = useCallback(
    async (participantId: string) => {
      const sessionId = service.getSessionId();

      if (!sessionId) {
        return false;
      }

      try {
        const voiceSession =
          await VoiceSessionDaoService.getVoiceSessionById(sessionId);
        const participant = voiceSession.participants.find(
          (p: Participant) => p.id === participantId
        );
        return participant;
      } catch (error) {
        dispatch(
          handleError({
            error,
            methodName: 'getSessionAndCheckIfConnected',
          })
        );
        return false;
      }
    },
    [dispatch, service]
  );

  const getSessionAndCheckIfDisconnected = useCallback(
    async (participantId: string) => {
      const sessionId = service.getSessionId();

      if (!sessionId) {
        return true;
      }

      try {
        const voiceSession =
          await VoiceSessionDaoService.getVoiceSessionById(sessionId);
        const participant = voiceSession.participants.find(
          (p: Participant) => p.id === participantId
        );
        return participant?.state === 'disconnected';
      } catch (error) {
        dispatch(
          handleError({
            error,
            methodName: 'getSessionAndCheckIfDisconnected',
            disableUserNotification: true,
          })
        );
        return true;
      }
    },
    [dispatch, service]
  );

  const deleteVoiceSession = useCallback(async () => {
    const sessionId = service.getSessionId();

    if (isPrimaryVcoSender) {
      try {
        await VoiceSessionDaoService.deleteVoiceSession({
          sessionId,
        });
      } catch (error) {
        dispatch(
          handleError({
            error,
            methodName: 'deleteVoiceSession',
          })
        );
      }
    }
    VoiceMeetingEventBus.finishVoiceSession$.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrimaryVcoSender]);

  const getVoiceSessionPayload = (
    phoneNumber: string
  ): VoiceSessionAddParticipantRequest => {
    const participant = createHearingParticipant(
      phoneNumber,
      deafPhoneNumberByMode
    );
    const sessionId = service.getSessionId();

    return {
      participant,
      sessionId,
      ...(deafPhoneNumberIsHidden ? { callId } : {}),
    };
  };

  return {
    completeMeeting,
    deleteVoiceSession,
    syncSession,
    startMeeting,
    addParticipant,
    addMonitorParticipant,
    disconnectParticipant,
    getSessionAndCheckIfDisconnected,
    getSessionAndReturnParticipant,
  };
};
