import { alpha, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CallFromIncomingIcon, MedicalCrossIcon } from 'assets/svg';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallStatus } from 'features/call/call-base/enums';
import { setCallStatus } from 'features/call/call-base/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import {
  deafPhoneNumberSelector,
  deafSelector,
} from 'features/call/call-deaf/store';
import { RnsEventBus } from 'features/call/call-events';
import {
  VrsCallReceivedParticipant,
  VrsCallReceivedParticipantsStyledContainer,
  VrsCallReceivedTakeCallButton,
} from 'features/call/call-received/components';
import {
  isEmergencyDialbackSelector,
  takeEmergencyCall,
} from 'features/emergency/store';
import { handleError } from 'features/notification/store';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { useWhileComponentMounted } from 'features/utils/hook';

export const EmergencyCallReceived = () => {
  const dispatch = useAppDispatch();
  const whileComponentMounted = useWhileComponentMounted();
  const [isLoading, setIsLoading] = useState(false);
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'callDetails' });
  const deaf = useAppSelector(deafSelector);
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);
  const deafPhoneNumber = useAppSelector(deafPhoneNumberSelector);
  const isEmergencyDialback = useAppSelector(isEmergencyDialbackSelector);
  const { handleDeafConnect } = useCallDeafParticipantHandlers();
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // this is the time we give Mercury to take call from Relay, if it doesn't transfer, or can't transfer we want to handle the abandoned call.
  // there is a already configured value of 30 seconds, but we want it to be much shorter than that.
  let timeToTransferEmergencyCall = useRef(15000);
  const [callerParticipant, calleeParticipant] = useMemo(() => {
    const deafParticipant = {
      name: deaf.name,
      phoneNumber: maskPhoneNumber(deaf.phoneNumber),
      location: deaf.location,
      icon: <CallFromIncomingIcon />,
    };

    const hearingParticipant = {
      name: '911 Center',
      phoneNumber: '',
      location: '',
      icon: <MedicalCrossIcon />,
    };

    if (isDeafToHearing) {
      return [deafParticipant, hearingParticipant];
    }

    return [hearingParticipant, deafParticipant];
  }, [deaf, isDeafToHearing]);

  const handleRegularEmergencyCall = () => {
    setIsLoading(true);

    timerRef.current = setTimeout(() => {
      handleAbandonedEmergencyCall();
    }, timeToTransferEmergencyCall.current);

    // INFO: We do not `setIsLoading(false)` here due to:
    //   1. `handleAbandonedEmergencyCall` from the `setTimeout` above may be called, the button still need to be loading.
    //   2. On successful `takeEmergencyCall` the `setCallStatus` will be called which will unmount this component with the loading button.
    dispatch(takeEmergencyCall());
  };

  const handleAbandonedEmergencyCall = async () => {
    try {
      setIsLoading(true);
      const call = await dispatch(takeEmergencyCall()).unwrap();
      dispatch(setCallStatus(CallStatus.CALL_IN_PROGRESS));
      handleDeafConnect(deafPhoneNumber, call.CalleeConnectionString);
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'handleAbandonedEmergencyCall',
        })
      );
    }
  };

  const handleTakeCall = () => {
    if (isEmergencyDialback) {
      return handleAbandonedEmergencyCall();
    }

    return handleRegularEmergencyCall();
  };

  useEffect(() => {
    RnsEventBus.$callDisconnected
      .pipe(whileComponentMounted())
      .subscribe((data) => {
        if (data?.IsEmergencyDialback) {
          timeToTransferEmergencyCall.current = 0;
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        console.log('debug clearing timer for take call VRS emergency');
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <Container>
      <StyledMedicalCrossIcon />
      <StyledTitle variant='h1' align='center'>
        {ct('incomingEmergencyCall')}
      </StyledTitle>
      <VrsCallReceivedParticipantsStyledContainer>
        <VrsCallReceivedParticipant
          title={t('callFrom')}
          {...callerParticipant}
        />
        <VrsCallReceivedParticipant
          title={t('callTo')}
          {...calleeParticipant}
        />
      </VrsCallReceivedParticipantsStyledContainer>
      <VrsCallReceivedTakeCallButton
        onClick={handleTakeCall}
        loading={isLoading}
      />
    </Container>
  );
};

const Container = styled('div')`
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${alpha(theme.palette.grey[900], 0.5)};
  border-radius: 8px;
  padding: ${theme.spacing(23)};
`;

const StyledMedicalCrossIcon = styled(MedicalCrossIcon)`
  & path {
    stroke: ${theme.palette.error.contrastText};
  }
`;

const StyledTitle = styled(Typography)`
  margin-top: ${theme.spacing(8)};
  margin-bottom: ${theme.spacing(16)};
  color: ${theme.palette.error.contrastText};
`;
