import { useMemo } from 'react';

import { useAppSelector } from 'features/app/hooks';
import {
  useCallDeafParticipantHandlers,
  useCallHearingParticipantHandlers,
} from 'features/call/call-accepted/hooks';
import { callSelector } from 'features/call/call-base/store/selectors';
import { deafSelector } from 'features/call/call-deaf/store';
import {
  CallDetailsButtonCalleeConnect,
  CallDetailsButtonCancel,
  CallDetailsButtonDeafConnect,
  CallDetailsButtonDeafDisconnect,
  CallDetailsButtonHearingDisconnect,
  CallDetailsParticipantIcon,
} from 'features/call/call-details/components';
import type { CallDetailsParticipantInfo } from 'features/call/call-details/interfaces';
import { isHearingToDeafCallerLoadingSelector } from 'features/call/call-hearing-to-deaf/store';
import { primaryHearingSelector } from 'features/call/call-hearing/store';
import {
  isAcceptingUserSelector,
  isTeamingInProgressSelector,
} from 'features/teaming/teaming-base/store';
import { voiceSessionStatusSelector } from 'features/voice-session/store';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import { CallDetailsParticipantVco2LineContainer } from 'features/vco-2-line/components';

export const useCallDetailsCaller = () => {
  const { isDeafToHearing } = useAppSelector(callSelector);
  const deaf = useAppSelector(deafSelector);
  const primaryHearing = useAppSelector(primaryHearingSelector);
  const isHearingToDeafCallerLoading = useAppSelector(
    isHearingToDeafCallerLoadingSelector
  );
  const isTeamingInProgress = useAppSelector(isTeamingInProgressSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);
  const voiceSessionStatus = useAppSelector(voiceSessionStatusSelector);

  const { handleDeafCancel } = useCallDeafParticipantHandlers();
  const { handleRegularHearingCall, handleHearingCancel } =
    useCallHearingParticipantHandlers();

  const deafToHearingCallerButton = useMemo(() => {
    if (deaf.status === 'connecting') {
      return (
        <CallDetailsButtonCancel
          loading={deaf.isLoading}
          onClick={handleDeafCancel}
        />
      );
    }

    if (deaf.status === 'connected') {
      return <CallDetailsButtonDeafDisconnect loading={deaf.isLoading} />;
    }

    if (deaf.status === 'disconnected') {
      return <CallDetailsButtonDeafConnect phoneNumber={deaf.phoneNumber} />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deaf.status, deaf.phoneNumber, deaf.isLoading, isTeamingInProgress]);

  const hearingToDeafCallerButton = useMemo(() => {
    if (isAcceptingUser) {
      return null;
    }
    if (
      primaryHearing.id &&
      primaryHearing.status === 'connecting' &&
      voiceSessionStatus === VoiceSessionStatus.ACTIVE
    ) {
      return (
        <CallDetailsButtonCancel
          loading={false}
          onClick={() => handleHearingCancel(primaryHearing.id)}
        />
      );
    }

    if (isHearingToDeafCallerLoading) {
      return <CallDetailsButtonCancel loading={true} />;
    }

    if (primaryHearing.status === 'connected') {
      return <CallDetailsButtonHearingDisconnect />;
    }

    if (primaryHearing.status === 'disconnected') {
      return (
        <CallDetailsButtonCalleeConnect
          isLoading={false}
          onClickHandler={() =>
            handleRegularHearingCall(primaryHearing.phoneNumber)
          }
        />
      );
    }
  }, [
    primaryHearing.status,
    primaryHearing.id,
    primaryHearing.phoneNumber,
    isHearingToDeafCallerLoading,
    isAcceptingUser,
    voiceSessionStatus,
    handleHearingCancel,
    handleRegularHearingCall,
  ]);

  const callerButton = useMemo(() => {
    if (isDeafToHearing) {
      return (
        <>
          {deafToHearingCallerButton}
          {!isAcceptingUser && <CallDetailsParticipantVco2LineContainer />}
        </>
      );
    }

    return hearingToDeafCallerButton;
  }, [
    hearingToDeafCallerButton,
    deafToHearingCallerButton,
    isDeafToHearing,
    isAcceptingUser,
  ]);

  const callerMetadata = useMemo(() => {
    if (isDeafToHearing) {
      return deaf;
    }
    return primaryHearing;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deaf.status,
    deaf.phoneNumber,
    primaryHearing.id,
    primaryHearing.status,
    primaryHearing.phoneNumber,
    primaryHearing.isWavelloRegistered,
    isDeafToHearing,
  ]);

  const caller = useMemo<CallDetailsParticipantInfo>(() => {
    return {
      ...callerMetadata,
      icon: <CallDetailsParticipantIcon isDeafParticipant={isDeafToHearing} />,
      children: callerButton,

      testId: isDeafToHearing ? 'deaf-details-item' : 'hearing-details-item',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callerMetadata, callerButton, isDeafToHearing]);

  return { caller };
};
