import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import type { Popover } from 'features/menu/interfaces';

export const usePopover = (id: string): Popover => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenPopover = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const popoverId = useMemo(
    () => (isOpenPopover ? id : undefined),
    [isOpenPopover, id]
  );

  const showPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  return { isOpenPopover, popoverId, anchorEl, showPopover, closePopover };
};
