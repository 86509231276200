import { Typography } from '@mui/material';
import type { FC } from 'react';
import { t } from 'i18next';

import { useNormalizeCallLocationName } from 'features/call/call-details/hooks';
import { ExtensionNumber } from 'features/call/call-details/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import { WavelloIcon } from 'features/wavello/icons';
import { ANONYMOUS } from 'features/voice-meeting/constants';

export interface CallDetailsParticipantMetadataProps {
  name: string;
  phoneNumber?: string;
  phoneExtension?: string;
  location?: string;
  isWavelloRegistered?: boolean;
  isWavelloCallActive?: boolean;
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const UserName = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 193px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${theme.palette.common.white};
`;

const StyledWavelloIcon = styled(WavelloIcon)`
  transform: translate(5px, 2px);
`;

const DisplayFlex = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PhoneNumber = styled(Typography)`
  color: ${theme.palette.grey[50]};
  margin-right: ${theme.spacing(4)};
`;

export const CallDetailsParticipantMetadata: FC<
  CallDetailsParticipantMetadataProps
> = ({
  name,
  phoneNumber,
  phoneExtension,
  location,
  isWavelloRegistered = false,
}) => {
  const title = t('wavello.available');

  const { normalizeLocationName } = useNormalizeCallLocationName();

  return (
    <Container>
      <UserName variant='textLargeMedium'>{name}</UserName>
      <DisplayFlex>
        {phoneNumber && phoneNumber !== ANONYMOUS && (
          <PhoneNumber variant='body1' color={theme.palette.grey[50]}>
            {maskPhoneNumber(phoneNumber)}
          </PhoneNumber>
        )}
        {phoneExtension && <ExtensionNumber phoneExtension={phoneExtension} />}
        {isWavelloRegistered && <StyledWavelloIcon title={title} />}
      </DisplayFlex>
      {location && (
        <Typography variant='label' color={theme.palette.grey[100]}>
          {normalizeLocationName(location)}
        </Typography>
      )}
    </Container>
  );
};
