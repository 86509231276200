import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  isHearingToDeafSelector,
  callIdSelector,
  callStatusSelector,
  peerUserAgentSelector,
  sipCallIdSelector,
} from 'features/call/call-base/store/selectors';
import { normalizeAnalyticsKeys } from 'features/analytics/helpers/';
import type { SendAnalyticsRequest } from 'features/analytics/interfaces';
import { AnalyticsDaoService } from 'features/analytics/services';
import type { RootState } from 'features/app/store/store';
import { LoggerService } from 'features/logger/services';
import { ProductService } from 'features/product/services';
import {
  agentNumberSelector,
  callCenterCodeSelector,
} from 'features/user/store';
import { voiceSessionIdSelector } from 'features/voice-session/store';

export const sendAnalytics = createAsyncThunk(
  'app/sendAnalytics',
  async (payload: SendAnalyticsRequest, { getState }) => {
    const state = getState() as RootState;
    const callStatus = callStatusSelector(state);
    const callId = callIdSelector(state);
    const agentNumber = agentNumberSelector(state);
    const callCenterCode = callCenterCodeSelector(state);
    const sipCallId = sipCallIdSelector(state);
    const voiceSessionId = voiceSessionIdSelector(state);
    const userAgent = peerUserAgentSelector(state);
    const checkedKeys = normalizeAnalyticsKeys(payload);
    const isHearingToDeaf = isHearingToDeafSelector(state);
    const webRtcConnections = state.callerSession.webRtcRemoteIdentities;
    const switchboardConnections = state.callerSession.switchboardConnections;
    const callerSessionStatus = state.callerSession.status;

    try {
      const event = {
        ...checkedKeys,
        CallStatus: callStatus,
        CallId: callId,
        AgentNumber: agentNumber,
        CallCenterCode: callCenterCode,
        SessionLoginHistoryId: state.session.id,
        MercuryVersion: ProductService.version ?? undefined,
        SipCallId: sipCallId ?? '',
        RnsConnectionId: state.session.rnsConnectionId ?? '',
        VoiceSessionId: voiceSessionId ?? '',
        Mode: state.multiMode.mode,
        EndpointType: userAgent ?? '',
        CallType: isHearingToDeaf ? 'H2D' : 'D2H',
        CallerSessionStatus: callerSessionStatus ?? '',
        WebRtcConnections: webRtcConnections,
        SwitchboardConnections: switchboardConnections,
      };
      await AnalyticsDaoService.sendAnalytics(event);
    } catch (error) {
      LoggerService.error(error);
    }
  }
);
