import { useCallback, useEffect } from 'react';

import { callIdSelector } from 'features/call/call-base/store/selectors';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { updateCalleePhoneNumber } from 'features/call/call-base/store';
import { setPrimaryHearingPhoneNumber } from 'features/call/call-hearing/store';
import {
  addCalledConnectionPoints,
  emergencyConnectionPointSelector,
  emergencyPhoneNumberSelector,
  isAddressVerificationNeededSelector,
  setIsEmergencyDeafReconnectionNeeded,
  turnOffSpawningEmergencyCall,
} from 'features/emergency/store';
import { isAcceptingUserSelector } from 'features/teaming/teaming-base/store';
import { useVoiceMeetingParticipant } from 'features/voice-meeting/hooks';

export const useEmergencyAutoConnect = () => {
  const dispatch = useAppDispatch();
  const { connectHearing } = useVoiceMeetingParticipant();
  const emergencyPhoneNumber = useAppSelector(emergencyPhoneNumberSelector);
  const isAddressVerificationNeeded = useAppSelector(
    isAddressVerificationNeededSelector
  );
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  );
  const callId = useAppSelector(callIdSelector);
  const isAcceptingUser = useAppSelector(isAcceptingUserSelector);

  const handleAutoConnectFinishOrCancel = useCallback(() => {
    dispatch(turnOffSpawningEmergencyCall());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const attemptAutoConnect = useCallback(async () => {
    try {
      await dispatch(updateCalleePhoneNumber()).unwrap();
      dispatch(setPrimaryHearingPhoneNumber(emergencyPhoneNumber));
      await connectHearing(emergencyPhoneNumber);
      if (emergencyConnectionPoint) {
        dispatch(addCalledConnectionPoints(emergencyConnectionPoint));
      }
      dispatch(setIsEmergencyDeafReconnectionNeeded(false));
    } finally {
      handleAutoConnectFinishOrCancel();
    }
    // INFO: Adding `connectHearing`, which is not wrapped with `useCallback`, may lead to infinite calls of this function.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyConnectionPoint, emergencyPhoneNumber]);

  useEffect(() => {
    if (
      !callId ||
      isAddressVerificationNeeded ||
      isAcceptingUser ||
      emergencyConnectionPoint === 'OTHER'
    ) {
      handleAutoConnectFinishOrCancel();
      return;
    }

    attemptAutoConnect();
  }, [
    callId,
    isAddressVerificationNeeded,
    isAcceptingUser,
    attemptAutoConnect,
    handleAutoConnectFinishOrCancel,
    emergencyConnectionPoint,
  ]);
};
